import "babel-polyfill";
import Vue from 'vue'
import App from './App.vue'
const VueCookie = require('vue-cookie');

import { LMap, LTileLayer, LMarker, LControlLayers } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import router from './router'

import Vant from 'vant';
import 'vant/lib/index.css';
import { createProvider } from './vue-apollo'

import { Locale, Lazyload } from 'vant';
import thTH from './utils/langth';

Locale.use('th-TH', thTH);
Vue.use(Vant);
Vue.use(Lazyload);
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-control-layers', LControlLayers);

Vue.use(VueCookie);

Vue.config.productionTip = false

new Vue({
  router,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
