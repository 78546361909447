<template>
  <div>
    <van-nav-bar title="เพิ่มเติม" />
    <van-row>
      <van-empty description="Description" />
    </van-row>
    <Tabbar />
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar.vue";
export default {
  name: "Trip",
  components: {
    Tabbar,
  },
  data() {
    return {
      active: 0,
    };
  },
  methods: {},
};
</script>