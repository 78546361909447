<template>
  <div>
    <van-field
      v-model="fieldValue"
      is-link
      readonly
      label="รถ"
      placeholder="เลือกรถ"
      @click="show = true"
    />
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="เลือกรถ"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
import gql from "graphql-tag";

const ALL_CHARACTERS_QUERY = gql`
  query GetDevices {
    devices {
      gpsid
      name
    }
  }
`;

export default {
  props: {
    current: {
      type: String,
      default: () => "",
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      show: false,
      devices: [],
      fieldValue: "",
      cascaderValue: "",
      options: [
        {
          text: "Zhejiang",
          value: "330000",
        },
        {
          text: "Jiangsu",
          value: "320000",
        },
      ],
    };
  },
  mounted() {
    this.getCarList();
    this.fieldValue = localStorage.getItem("selected_car");
    this.cascaderValue = localStorage.getItem("selected_car_value");
  },
  methods: {
    async getCarList() {
      try {
        const res = await this.$apollo.query({
          query: ALL_CHARACTERS_QUERY,
          fetchPolicy: "network-only",
        });
        this.loading = false;
        if (res) {
          this.options = [];
          for (const item of res.data.devices) {
            this.options.push({
              text: item.name,
              value: item.gpsid,
            });
          }
        }
      } catch (err) {
        this.loading = false;
        this.options = [];
      }
    },
    onFinish({ selectedOptions, value }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
      localStorage.setItem("selected_car", this.fieldValue);
      localStorage.setItem("selected_car_value", value);
      this.onChange({ selectedOptions, value });
    },
  },
};
</script>