<template>
  <div class="home">
    <Map
      id="map-component"
      :markers="markers"
      :handleMarker="markerClick"
      :onMapClick="mapClick"
      style="height: calc(100vh - 50px)"
    />
    <van-popup
      safe-area-inset-bottom
      v-model="showShare"
      round
      position="bottom"
      :style="{ height: '310px' }"
      :overlay="overlay"
      duration="0.1"
      v-if="selectedDevice"
    >
      <van-row>
        <van-col span="24">
          <div style="padding: 12px 16px 4px; text-align: center">
            {{ selectedDevice.data.name }}
          </div>
        </van-col>
      </van-row>
      <van-row>
        <van-row style="padding: 12px; height: 80px">
          <!-- <van-col> <van-empty description="Description" /></van-col> -->
          <van-empty description="ไม่มีการเดินทางในวันนี้" />
        </van-row>
      </van-row>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import Map from "@/components/Map.vue";
const { formatDevicetoMap } = require("../utils/map");

import gql from "graphql-tag";

const ALL_CHARACTERS_QUERY = gql`
  query GetDevices {
    devices {
      gpstype
      gpsid
      name
      created
      current {
        geo {
          latitude
          longitude
          address
          bearing
        }
        status {
          engine
          power
        }
        speed {
          kmh
        }
        created
      }
    }
  }
`;

export default {
  name: "Tracking",
  components: {
    Map,
  },
  data() {
    return {
      interval: null,
      devices: [],
      markers: [],
      showShare: true,
      overlay: false,
      selectedDevice: null,
    };
  },
  mounted() {
    this.updateMap();
    this.interval = setInterval(() => {
      this.updateMap();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    markerClick(data) {
      console.log(data);
      this.selectedDevice = data;
      this.showShare = true;
    },
    mapClick() {
      this.showShare = false;
    },
    async updateMap() {
      try {
        const res = await this.$apollo.query({
          query: ALL_CHARACTERS_QUERY,
          fetchPolicy: "network-only",
        });

        if (res) {
          this.loading = false;
          const { devices } = res.data;

          this.devices = devices.filter((i) => i.current);

          this.markers = [];
          for (const item of this.devices) {
            this.markers.push(formatDevicetoMap(item));
          }
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.devices = [];
      }
    },
    alertBox(marker) {
      console.log(marker);
    },
  },
};
</script>
