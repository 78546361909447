// const moment = require('moment')
const dayjs = require('dayjs')
const { divIcon } = require('leaflet')

function generateIcon(data, detail) {
    let img
    const name = data.name || data.gpsid
    const speed = data.current.speed.kmh
    const bearing = data.current.geo.bearing
    const engine = data.current.status.engine
    const power = data.current.status.power
    if (engine && power) {
        if (speed > 100) {
            img = '/static/markers/orange_tran.png'
        } else if (speed > 50) {
            img = '/static/markers/green_tran.png'
        } else {
            img = '/static/markers/blue_tran.png'
        }
    } else if (!engine && !power) {
        img = '/static/markers/red_tran.png'
    } else if (!engine && power) {
        if (speed > 5) {
            img = '/static/markers/green_tran.png'
        } else {
            img = '/static/markers/gray_tran.png'
        }
    } else if (engine && !power) {
        img = '/static/markers/yellow_tran.png'
    }

    // img = '/static/markers/car.png'
    const detailTable = (detail ? `<div style="font-family: 'IBM Plex Sans Thai'; margin-top: 3px; width: 100px; box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px; background: #ffffffff; font-size: 13px;">${name}<br><small>(${Math.ceil(data.current.speed.kmh)} km/h)</small></div>` : '')
    const html = `<div style="width: 100px; text-align: center; font-weight: bold; "> <div style="width: 100%; height: 40px; "><img src="${img}" style="transform: rotate(${bearing}deg); width: 20px;"></div>${detailTable}</div>`
    return {
        className: 'my-div-icon',
        html,
        popupAnchor: [-12, -40],
        iconAnchor: [62, 25]
    }
}

function formatDevicetoMap(item, detail = true) {
    return {
        id: item.gpsid,
        icon: divIcon(generateIcon(item, detail)),
        position: { lat: item.current.geo.latitude, lng: item.current.geo.longitude },
        tooltip: getTableDetail(item),
        data: item
    }
}

function formatEventtoMap(item) {
    return {
        id: item._id || item.__id || item.device._id,
        icon: divIcon(eventIcon(item)),
        position: { lat: item.geo.latitude, lng: item.geo.longitude },
        tooltip: getTableDetail(item),
        data: item
    }
}

function eventIcon() {
    const img = '/img/markers/stop.png'
    return {
        className: 'my-div-icon',
        html: `<div style="width: 15px; text-align: center;"> <img src="${img}" width="15px"></div>`,
        popupAnchor: [0, -15],
        iconAnchor: [7, 15]
    }
}

function getTableDetail(item) {
    const time = getFormatTime(item.current.created)
    let text = `<table class="table" style="font-size: 12px; ">
  <tr><td style="font-weight: bold; padding-right: 10px;">รหัส: </td><td>${item.gpsid || item._id || item.device?._id}</td></tr>`
    if (item.name) {
        text += `<tr><td style="font-weight: bold; padding-right: 10px;">ชื่อ: </td><td>${item.name || item.device?.name || '-'}</td></tr>`
    }
    text += `<tr><td style="font-weight: bold; padding-right: 10px;">ตำแหน่ง: </td>
  <td>
      <a href="http://maps.google.com/maps?q=${item.current.geo.latitude},${item.current.geo.longitude}&amp;t=m" target="_blank" style="text-decoration: none;">
      ${item.current.geo.latitude.toFixed(5)} °, ${item.current.geo.longitude.toFixed(5)} °
      </a>
  </td>
  </tr>
  <tr><td style="font-weight: bold; padding-right: 10px;">มุม: </td><td>${item.current.geo.bearing}°</td></tr>
  <tr><td style="font-weight: bold; padding-right: 10px;">ความเร็ว: </td><td>${Math.ceil(item.current.speed.kmh)} km/h</td></tr>
  <tr><td style="font-weight: bold; padding-right: 10px;">เวลา: </td><td>${time}</td></tr>
  </table>`

    return text
}

function getFormatTime(time) {
    // return moment(time).format('DD/MM/YYYY HH:mm:ss')
    // return time
    return dayjs(time).format('DD/MM/YYYY HH:mm:ss')
}

function msToTime(duration) {
    let seconds = parseInt((duration / 1000) % 60)
    let minutes = parseInt((duration / (1000 * 60)) % 60)
    let hours = parseInt((duration / (1000 * 60 * 60)) % 24)

    hours = (hours < 10) ? '0' + hours : hours
    minutes = (minutes < 10) ? '0' + minutes : minutes
    seconds = (seconds < 10) ? '0' + seconds : seconds

    return hours + ':' + minutes + ':' + seconds
}

function downloadLink(blob, filename) {
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
}

export { generateIcon, formatDevicetoMap, getFormatTime, getTableDetail, formatEventtoMap, msToTime, downloadLink }
