<template>
  <div>
    <van-nav-bar title="ค่าใช้จ่าย" />
    <van-row>
      <CarList :onChange="onCarChange" />

      <van-empty
        v-if="trips.length === 0"
        description="ไม่มีรายการค่าใช้จ่าย"
      />
    </van-row>
    <Tabbar />
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar.vue";
import CarList from "@/components/CarList.vue";
import gql from "graphql-tag";

const ALL_CHARACTERS_QUERY = gql`
  query {
    trips(gpsid: "9170424256") {
      gpsid
      startDate
      endDate
      startOdometer
      endOdometer
      startGeo {
        latitude
        longitude
      }
      endGeo {
        latitude
        latitude
      }
      speed {
        avg
        max
      }
      canbus {
        speed
        total_mileage
        total_fuel_consumption_volume
        fuel_consumption_volume
      }
      expenses {
        type
        price
      }
      duration
      distance
    }
  }
`;

export default {
  name: "Trip",
  components: {
    Tabbar,
    CarList,
  },
  data() {
    return {
      loading: true,
      trips: [],
    };
  },
  methods: {
    async onCarChange({ value }) {
      console.log(value);
    },
    async getTrips() {
      try {
        const res = await this.$apollo.query({
          query: ALL_CHARACTERS_QUERY,
          fetchPolicy: "network-only",
        });

        if (res) {
          this.loading = false;
          const { trips } = res.data;
          this.trips = trips;
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
  },
};
</script>