<template>
  <div style="height: 100%; width: 100%">
    <l-map
      v-if="showMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 100%"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @click="onMapClick"
    >
      <!-- <l-tile-layer :url="url" :attribution="attribution" /> -->
      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"
      />
      <l-marker
        v-for="marker in markers"
        :key="marker.id"
        :lat-lng="marker.position"
        :icon="marker.icon"
        @click="innerClick(marker)"
      >
        <!-- <l-popup :content="marker.tooltip" /> -->
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    // LPopup,
  },
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    handleMarker: {
      type: Function,
      default: () => {},
    },
    onMapClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      zoom: 6,
      center: latLng(13.7717449, 100.9163648),
      // url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(13.7717449, 100.9163648),
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: false,
      tileProviders: [
        {
          name: "OpenStreetMap",
          visible: false,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "Longdo Map",
          visible: true,
          url: "https://ms.longdo.com/mmmap/tile.php?zoom={z}&x={x}&y={y}&key=9542b4a7fa6f2e9b0e3846ea64e2ff83&proj=epsg3857&HD=1",
          attribution: "",
        },
        {
          name: "Mapbox Map",
          visible: false,
          url: "https://api.mapbox.com/styles/v1/iaunn/cjzlbq1tn02zi1cmwhdy4bodh/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaWF1bm4iLCJhIjoiY2owMXJuNjBsMDZuYjJ3b3h2ajA3NjBoaiJ9.94FRMLxLh0ByP7ROCRBSTg",
          attribution: "",
        },
        {
          name: "Google Map",
          visible: false,
          url: "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
          attribution: "",
        },
      ],
    };
  },
  mounted() {
    let currentZoom = this.$cookie.get("currentZoom");
    let currentCenter = this.$cookie.get("currentCenter");
    if (currentZoom) {
      this.zoom = +currentZoom;
    }

    if (currentCenter) {
      let pos = currentCenter.split(",");
      this.center = latLng(+pos[0], +pos[1]);
    }
    this.showMap = true;
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
      this.$cookie.set("currentZoom", zoom);
    },
    centerUpdate(center) {
      this.currentCenter = center;
      this.$cookie.set("currentCenter", `${center.lat},${center.lng}`);

      console.log("update center", `${center.lat},${center.lng}`);
    },
    innerClick(marker) {
      this.handleMarker(marker);
    },
  },
};
</script>

<style>
#mapCom {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.leaflet-popup-content-wrapper {
  border-radius: 0px;
}
.leaflet-draw-actions-top a {
  color: #ffffff;
}
</style>