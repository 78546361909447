<template>
  <div class="page-content page-container" id="page-content">
    <div class="padding">
      <div class="row">
        <div class="col-lg-6">
          <div class="timeline block mb-4">
            <div
              class="tl-item"
              v-for="item of generateTimeline(timeline)"
              :key="item._id"
            >
              <div class="tl-dot">
                <a class="tl-author" href="#" data-abc="true"
                  ><span class="w-32 avatar circle gd-info"
                    ><b-img :src="item.icon"></b-img></span
                ></a>
              </div>
              <div class="tl-content">
                <div class="" v-if="item.type === 'D'">
                  {{ item.descption }}
                </div>
                <div class="" v-if="item.type === 'P'">
                  {{ item.descption }}
                </div>
                <div class="" v-if="item.type === 'start'">สตาร์ทเครื่อง</div>
                <div class="" v-if="item.type === 'stop'">ดับเครื่อง</div>
                <div class="" v-if="item.type === 'park'">จอดแวะ</div>

                <div class="tl-date text-muted mt-1" v-if="item.type === 'D'">
                  {{ (item.stats.distance / 1000).toFixed(1) }} กม.
                  {{ (item.stats.duration / 60000).toFixed(0) }} นาที
                  {{ item.startDate | thTime }} - {{ item.endDate | thTime }}
                  <!-- <pre> {{ JSON.stringify(item, null, 2) }}</pre> -->
                </div>

                <div class="tl-date text-muted mt-1" v-if="item.type === 'P'">
                  {{ (item.stats.duration / 60000).toFixed(0) }} นาที.
                  {{ item.stats.start | thTime }} -
                  {{ item.stats.end | thTime }}
                  <!-- <pre> {{ JSON.stringify(item, null, 2) }}</pre> -->
                </div>

                <div
                  class="tl-date text-muted mt-1"
                  v-if="item.type === 'park'"
                >
                  {{ item.geo.address }}<br />
                  {{ (calculateTime(item) / 60000).toFixed(0) }} นาที
                  {{ item.startDate | thTime }} - {{ item.endDate | thTime }}
                  <!-- <pre> {{ JSON.stringify(item, null, 2) }}</pre> -->
                </div>

                <div
                  class="tl-date text-muted mt-1"
                  v-if="item.type == 'start' || item.type == 'stop'"
                >
                  {{ item.geo.address }}<br />
                  {{ item.startDate | thDate }}
                </div>
              </div>
            </div>
            <!-- <div class="tl-item">
              <div class="tl-dot">
                <a class="tl-author" href="#" data-abc="true"
                  ><span class="w-32 avatar circle gd-warning"
                    ><img
                      src="https://img.icons8.com/color/16/000000/person-female.png"
                      alt="." /></span
                ></a>
              </div>
              <div class="tl-content">
                <div class="">
                  Do you know how <a href="#" data-abc="true">Google</a> search
                  works.
                </div>
                <div class="tl-date text-muted mt-1">15 days ago</div>
              </div>
            </div>
            <div class="tl-item">
              <div class="tl-dot">
                <a class="tl-author" href="#" data-abc="true"
                  ><span class="w-32 avatar circle gd-info"
                    ><img
                      src="https://img.icons8.com/office/16/000000/guest-male.png"
                      alt="." /></span
                ></a>
              </div>
              <div class="tl-content">
                <div class="">
                  Order placed <a href="#" data-abc="true">@google</a> Store
                </div>
                <div class="tl-date text-muted mt-1">12 days ago</div>
              </div>
            </div>
            <div class="tl-item">
              <div class="tl-dot">
                <a class="tl-author" href="#" data-abc="true"
                  ><span class="w-32 avatar circle gd-info">B</span></a
                >
              </div>
              <div class="tl-content">
                <div class="">
                  <a href="#" data-abc="true">Thanks for </a>your support
                </div>
                <div class="tl-date text-muted mt-1">5 hours ago</div>
              </div>
            </div>
            <div class="tl-item">
              <div class="tl-dot">
                <a class="tl-author" href="#" data-abc="true"
                  ><span class="w-32 avatar circle gd-warning">H</span></a
                >
              </div>
              <div class="tl-content">
                <div class="">
                  One of the best way to have single handed car
                </div>
                <div class="tl-date text-muted mt-1">12 jan, 2019</div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const dayjs = require("dayjs");
// const duration = require("dayjs/plugin/duration");
export default {
  props: {
    timeline: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      timelines: [],
    };
  },
  filters: {
    thDate: function (value) {
      if (!value) return "";
      return dayjs(value).format("DD/MM/YYYY HH:mm:ss");
    },
    thTime: function (value) {
      if (!value) return "";
      return dayjs(value).format("HH:mm:ss");
    },
  },
  methods: {
    generateTimeline(timeline) {
      let timelines = [];
      let i = 0;
      for (let item of timeline) {
        if (i != 0) {
          if (item.previous.distance === 0 && item.previous.duration > 0) {
            timelines.push({
              type: "P",
              descption: "จอด",
              stats: item.previous,
              icon: "https://img-cdn.24gps.co/https://img.icons8.com/color/16/000000/parking.png",
              startDate: item.startDate,
              endDate: item.endDate,
              raw: item,
            });
          } else if (item.previous.distance > 0 && item.previous.duration > 0) {
            timelines.push({
              type: "D",
              descption: "ขับรถ",

              stats: item.previous,
              icon: "https://img-cdn.24gps.co/https://img.icons8.com/fluency/16/000000/car.png",
              startDate: item.previous.start,
              endDate: item.previous.end,
              raw: item,
            });
          }
        }
        if (item.type === "park") {
          item.icon =
            "https://img-cdn.24gps.co/https://img.icons8.com/color/16/000000/parking.png";
        }
        if (item.type === "stop") {
          item.icon =
            "https://img-cdn.24gps.co/https://img.icons8.com/color/20/000000/stop-sign.png";
        }
        if (item.type === "start") {
          item.icon =
            "https://img-cdn.24gps.co/https://img.icons8.com/nolan/20/engine.png";
        }

        timelines.push(item);
        i++;
      }

      return timelines;
    },
    calculateTime(trip) {
      const date1 = dayjs(trip.endDate);
      const date2 = dayjs(trip.startDate);
      return date1.diff(date2, "millisecond");
    },
    convertTimeMS(ms) {
      // 1- Convert to seconds:
      let seconds = ms / 1000;
      // 2- Extract hours:
      const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
      seconds = seconds % 3600; // seconds remaining after extracting hours
      // 3- Extract minutes:
      const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
      // 4- Keep only seconds not extracted to minutes:
      seconds = seconds % 60;
      return hours + ":" + minutes;
    },
  },
};
</script>
<style scoped>
body {
  background-color: #f9f9fa;
}

@media (min-width: 992px) {
  .page-container {
    max-width: 1140px;
    margin: 0 auto;
  }

  .page-sidenav {
    display: block !important;
  }
}

.padding {
  padding: 2rem;
}

.w-32 {
  width: 32px !important;
  height: 32px !important;
  font-size: 0.85em;
}

.tl-item .avatar {
  z-index: 2;
}

.circle {
  border-radius: 500px;
}

.gd-warning {
  color: #fff;
  border: none;
  background: #f4c414 linear-gradient(45deg, #f4c414, #f45414);
}

.timeline {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
  padding: 0;
  margin: 0;
}

.p-4 {
  padding: 1.5rem !important;
}

.block,
.card {
  background: #fff;
  border-width: 0;
  border-radius: 0.25rem;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5); */
  margin-bottom: 1.5rem;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.tl-item {
  border-radius: 3px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
}

.tl-item > * {
  padding: 10px;
}

.tl-item .avatar {
  z-index: 2;
}

.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-item.active .tl-dot:before {
  border-color: #448bff;
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-item.active .tl-dot:before {
  border-color: #448bff;
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.tl-dot:after {
  width: 0;
  height: auto;
  top: 25px;
  bottom: -15px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}

tl-item.active .tl-dot:before {
  border-color: #448bff;
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.tl-dot:after {
  width: 0;
  height: auto;
  top: 25px;
  bottom: -15px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}

.tl-content p:last-child {
  margin-bottom: 0;
}

.tl-date {
  font-size: 11px;
  margin-top: 2px;
  /* min-width: 100px;
  max-width: 100px; */
}

.avatar {
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 500px;
  box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
}

.gd-info {
  background-color: #fff;
}
</style>