<template>
  <div>
    <van-nav-bar title="ประวัติ" />
    <van-row>
      <CarList :onChange="onCarChange" />

      <van-field
        :value="date"
        is-link
        readonly
        label="ช่วงเวลา"
        placeholder="เลือกช่วงเวลา"
        @click="show = true"
      />

      <van-calendar
        v-model="show"
        type="range"
        allow-same-day
        :max-range="7"
        @confirm="onConfirm"
        :min-date="minDate"
        :max-date="maxDate"
      />
      <!-- https://bbbootstrap.com/snippets/user-business-timeline-32289439 -->
      <van-empty
        v-if="timeline.length === 0"
        description="ไม่มีประวัติการเดินทาง"
      />

      <!-- <div v-for="item in timeline" :key="item._id">{{ item._id }}</div> -->
      <!-- <van-cell
        v-for="item in timeline"
        :key="item._id"
        :title="item.startDate | thDate"
        :label="item.geo.address"
        :value="item.type"
      /> -->

      <Timeline :timeline="timeline" />
    </van-row>
    <Tabbar />
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar.vue";
import CarList from "@/components/CarList.vue";
import Timeline from "@/components/Timeline.vue";

import gql from "graphql-tag";
const dayjs = require("dayjs");

const ALL_CHARACTERS_QUERY = gql`
  query getTimeLine($gpsid: String, $start: Date, $end: Date) {
    timeline(gpsid: $gpsid, start: $start, end: $end) {
      _id
      type
      startDate
      endDate
      odometer
      geo {
        address
      }
      tripId
      previous {
        distance
        duration
        start
        end
      }
      park {
        distance
        duration
      }
    }
  }
`;

export default {
  name: "Trip",
  components: {
    Tabbar,
    CarList,
    Timeline,
  },
  data() {
    return {
      loading: false,
      finished: false,
      car: "",
      start: null,
      end: null,
      date: "",
      show: false,
      minDate: new Date(dayjs().subtract(90, "day")),
      maxDate: new Date(),
      timeline: [],
    };
  },
  filters: {
    thDate: function (value) {
      if (!value) return "";
      return dayjs(value).format("DD/MM/YYYY HH:mm:ss");
    },
    textLimit: function (value) {
      if (!value) return "";
      return value.substring(0, 50);
    },
  },
  mounted() {
    this.car = localStorage.getItem("selected_car_value");
  },
  methods: {
    async onCarChange({ value }) {
      this.car = value;
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      const startDate = new Date(dayjs(start).startOf("day"));
      const endDate = new Date(dayjs(end).endOf("day"));
      this.getTimeline(this.car, startDate, endDate);
    },
    async getTimeline(id, start, end) {
      try {
        this.loading = true;
        const res = await this.$apollo.query({
          query: ALL_CHARACTERS_QUERY,
          variables: { gpsid: id, start, end },
          fetchPolicy: "network-only",
        });

        if (res) {
          this.loading = false;
          const { timeline } = res.data;
          this.timeline = timeline;
          this.finished = true;
          console.log(this.timeline);
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>