<template>
  <div>
    <van-row> <Home /> </van-row>
    <Tabbar />
  </div>
</template>

<script>
import Home from "@/components/Tracking.vue";
import Tabbar from "@/components/Tabbar.vue";
export default {
  name: "Live",
  components: {
    Home,
    Tabbar,
  },
  data() {
    return {
      active: 0,
    };
  },
};
</script>