<template>
  <div>
    <van-nav-bar title="การเดินทาง" />

    <van-row style="padding-bottom: 60px">
      <CarList :onChange="onCarChange" />

      <van-empty v-if="trips.length === 0" description="ไม่มีการเดินทาง" />
      <!-- <van-card
        v-for="trip of trips"
        :key="trip._id"
        :title="trip.startGeo.address"
        :desc="trip.endGeo.address"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
      /> -->
      <b-container fluid>
        <b-row>
          <b-col cols="12" v-for="trip of trips" :key="trip._id">
            <div class="mt-2 mb-2 border">
              <b-row class="p-3 pb-0">
                <b-col cols="8" class="text-left">
                  <b-row class="pb-3">
                    <b-col cols="2" class="text-aria center"
                      ><span>จาก</span></b-col
                    >
                    <b-col cols="10">
                      <span class="address-text">
                        {{ trip.startGeo.address }}
                      </span>

                      <small class="address-text-small">{{
                        trip.startDate | thDate
                      }}</small>
                    </b-col>
                  </b-row>
                  <b-row class="border-top pt-3">
                    <b-col cols="2" class="text-aria center"
                      ><span>ถึง</span></b-col
                    ><b-col cols="10">
                      <span class="address-text">
                        {{ trip.endGeo.address }}
                      </span>

                      <small class="address-text-small">{{
                        trip.endDate | thDate
                      }}</small>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="4">
                  <!-- <b-img-lazy
                    :src="`https://portal-api.24gps.co/path/${trip._id}/image`"
                    fluid
                    rounded
                  ></b-img-lazy
                > -->

                  <van-image
                    show-loading
                    fit="contain"
                    show-error
                    width="100%"
                    height="100%"
                    lazy-load
                    :src="`https://img-cdn.24gps.co/https://portal-api.24gps.co/path/${trip._id}/image`"
                  />
                </b-col>
              </b-row>
              <hr />
              <b-row class="pb-2 text-center stats-text">
                <b-col>
                  {{ calculateDistance(trip) }}<small> กม.</small
                  ><br />ระยะทาง</b-col
                >
                <b-col>
                  {{ calculateTime(trip) }}<small> ชม.</small
                  ><br />ระยะเวลา</b-col
                >
                <b-col>
                  {{ trip.expenseSummary ? trip.expenseSummary.total : 0
                  }}<small> บาท</small><br />ค่าใช้จ่าย</b-col
                >
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </van-row>
    <Tabbar />
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar.vue";
import CarList from "@/components/CarList.vue";
import gql from "graphql-tag";
const dayjs = require("dayjs");
const duration = require("dayjs/plugin/duration");
const ALL_CHARACTERS_QUERY = gql`
  query GetTrips($gpsid: String) {
    trips(gpsid: $gpsid) {
      _id
      gpsid
      startDate
      endDate
      startOdometer
      endOdometer
      startGeo {
        latitude
        longitude
        address
      }
      endGeo {
        latitude
        latitude
        address
      }
      speed {
        avg
        max
      }
      canbus {
        speed
        total_mileage
        total_fuel_consumption_volume
        fuel_consumption_volume
      }
      expenseSummary {
        total
        count
      }
      duration
      distance
    }
  }
`;

export default {
  name: "Trip",
  components: {
    Tabbar,
    CarList,
  },
  data() {
    return {
      loading: true,
      trips: [],
    };
  },
  filters: {
    thDate: function (value) {
      if (!value) return "";
      return dayjs(value).format("DD/MM/YYYY HH:mm:ss");
    },
    textLimit: function (value) {
      if (!value) return "";
      return value.substring(0, 50);
    },
  },
  mounted() {
    dayjs.extend(duration);
    const selectedCar = localStorage.getItem("selected_car_value");
    if (selectedCar) {
      this.getTrips(selectedCar);
    }
  },
  methods: {
    async onCarChange({ value }) {
      this.getTrips(value);
    },
    async getTrips(id) {
      try {
        this.loading = true;
        const res = await this.$apollo.query({
          query: ALL_CHARACTERS_QUERY,
          variables: { gpsid: id },
          fetchPolicy: "network-only",
        });

        if (res) {
          this.loading = false;
          const { trips } = res.data;
          this.trips = trips;
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    calculateDistance(trip) {
      return ((trip.endOdometer - trip.startOdometer) / 1000).toFixed(1);
    },
    calculateTime(trip) {
      const date1 = dayjs(trip.endDate);
      const date2 = dayjs(trip.startDate);
      return this.convertTimeMS(date1.diff(date2, "millisecond"));
    },
    convertTimeMS(ms) {
      // 1- Convert to seconds:
      let seconds = ms / 1000;
      // 2- Extract hours:
      const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
      seconds = seconds % 3600; // seconds remaining after extracting hours
      // 3- Extract minutes:
      const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
      // 4- Keep only seconds not extracted to minutes:
      seconds = seconds % 60;
      return hours + ":" + minutes;
    },
  },
};
</script>

<style scoped>
.text-aria {
  font-size: 12px;
}

.center span {
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
  height: 100%;
}

.center {
  text-align: center;
}

.address-text {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.address-text-small {
  font-size: 11px;
}

.stats-text {
  font-size: 13px;
}
</style>